// Adjust Bulma footer padding
$footer-padding: 0.5rem 0.5rem 0.5rem;

// Import all of Bulma
@import "~bulma/sass/utilities/_all.sass";
@import "~bulma/sass/base/_all.sass";
@import "~bulma/sass/elements/_all.sass";
@import "~bulma/sass/form/_all.sass";
@import "~bulma/sass/components/_all.sass";
@import "~bulma/sass/grid/_all.sass";
@import "~bulma/sass/helpers/_all.sass";
@import "~bulma/sass/layout/_all.sass";

@import "~bulma-extensions/bulma-timeline/src/sass/index.sass";
@import "~bulma-extensions/bulma-steps/src/sass/index.sass";
